<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="mt-0 mb-2">
        {{ $t('developer-tab.zendesk-credentials.title') }}
      </h1>
      <button
        class="mx-2 px-3 btn btn-primary btn-sm"
        @click="generate"
        :disabled="isCreating"
      >
        {{
          isCreating
            ? 'loading...'
            : $t('developer-tab.zendesk-credentials.generate')
        }}
      </button>
    </div>
    <data-table
      ref="zendeskCredentials"
      :data="credentials"
      :fields="translatedFields"
      :isLoading="credentialsIsLoading"
      :pagination="pagination"
    >
      <template slot="actions" slot-scope="props">
        <button
          class="mx-2 px-3 btn btn-secondary btn-sm"
          @click="restoreCredential(props.rowData.id)"
          v-if="props.rowData.isDeleted"
          :disabled="isRestoring[props.rowData.id]"
        >
          {{
            isRestoring[props.rowData.id]
              ? 'loading...'
              : $t('developer-tab.zendesk-credentials.table.restore')
          }}
        </button>
        <button
          class="mx-2 px-3 btn btn-danger btn-sm"
          @click="deleteCredential(props.rowData.id)"
          v-if="!props.rowData.isDeleted"
        >
          {{
            isDeleting[props.rowData.id]
              ? 'loading...'
              : $t('developer-tab.zendesk-credentials.table.delete')
          }}
        </button>
      </template>
    </data-table>

    <modal :isOpen="isModalOpen" @dismiss="isModalOpen = false">
      <h4 class="m-0 mb-2">
        {{ $t('developer-tab.zendesk-credentials.toasts.create') }}
      </h4>
      <div
        class="d-flex justify-content-center align-items-center gap-2 p-2 border rounded"
        role="button"
        @click="copyApiKey()"
      >
        <span class="api-key">
          {{
            createdCredential.apiKey && createdCredential.apiKey.slice(0, 40)
          }}...
        </span>
        <i class="fa fa-clipboard" aria-hidden="true"></i>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue'
import ZendeskCredentialsService from '../../../../services/zendeskCredentials.service'
import DataTable from '../../../../elements/DataTable.vue'
import Modal from '../../../../elements/Modal'

import apiKey from './cells/apiKey.vue'
import isDeleted from './cells/isDeleted.vue'

Vue.component('apiKey', apiKey)
Vue.component('isDeleted', isDeleted)

export default {
  components: { DataTable, Modal },
  data() {
    return {
      credentials: [],
      fields: [
        {
          name: '__component:apiKey',
          title: 'developer-tab.zendesk-credentials.table.api-key'
        },
        {
          name: '__component:isDeleted',
          title: 'developer-tab.zendesk-credentials.table.is-deleted'
        },
        {
          name: '__slot:actions',
          title: 'developer-tab.zendesk-credentials.table.actions'
        }
      ],
      pagination: {
        perPage: 10
      },
      credentialsIsLoading: false,
      isCreating: false,
      isRestoring: {},
      isDeleting: {},
      isModalOpen: false,
      createdCredential: {}
    }
  },
  computed: {
    translatedFields() {
      return this.fields.map(field => ({
        ...field,
        title: this.$t(field.title)
      }))
    }
  },
  methods: {
    async fetchCredentials() {
      const credentials = await ZendeskCredentialsService.fetchAllCredentials()
      this.credentials = credentials
    },
    async generate() {
      this.isCreating = true
      try {
        const credential = await ZendeskCredentialsService.createCredential()
        this.fetchCredentials()
        this.createdCredential = credential
        this.isModalOpen = true
      } catch (error) {
        console.log(error)
      } finally {
        this.isCreating = false
      }
    },
    async deleteCredential(id) {
      try {
        this.isDeleting[id] = true
        console.log(this.isDeleting)
        await ZendeskCredentialsService.deleteCredential(id)
        this.toast(this.$t('developer-tab.zendesk-credentials.toasts.delete'))
        this.fetchCredentials()
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
        this.isDeleting[id] = false
      }
    },
    async restoreCredential(id) {
      try {
        this.isRestoring[id] = true
        await ZendeskCredentialsService.restoreCredential(id)
        this.toast(this.$t('developer-tab.zendesk-credentials.toasts.restore'))
        this.fetchCredentials()
      } catch (error) {
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
        this.isRestoring[id] = false
      }
    },
    copyApiKey() {
      this.copyToClipboard(this.createdCredential.apiKey)
    }
  },
  mounted() {
    this.fetchCredentials()
  }
}
</script>

<style scoped lang="scss">
.gap-2 {
  gap: 0.5rem;
}
</style>
